import React, { FC, useEffect, useState } from "react";
import Api from "../utils/Api";
import closeIcon from "../../images/IconAnnouncementExit.svg";
import dayjs from "dayjs";
import { useSessionStore } from "../storez/SessionStore";

interface Announcement {
  // System Incident
  id: string;
  title: string;
  name: string;
  type: string;

  // Maintenance
  start?: string;
  end?: string;

  // Internal use
  hideme?: boolean;
}

interface StatusIoResponse {
  announcements: Announcement[];
}

export const AnnouncementBar: FC = () => {
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);

  const getDevice = useSessionStore((state) => state.getDevice);

  useEffect(() => {
    const statusIoInterval = setInterval(() => {
      // Fetch the status of incidents and maintenances for the announcement banners, this does not need to be frequent, currently set to 5 minutes
      fetchAnnouncements();
    }, 300000);

    setTimeout(() => {
      // Initial call
      fetchAnnouncements();
    }, 0);

    return () => {
      clearInterval(statusIoInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAnnouncements = () => {
    if (getDevice()) {
      Api.get(
        "/announcements",
        (result: StatusIoResponse) => {
          statusChange(result);
        },
        (err: unknown) => {
          console.log(err);
        }
      );
    }
  };

  const checkIfAnnouncementExists = (announcementId: string) => {
    for (let i = 0; i < announcements.length; i++) {
      if (announcements[i].id === announcementId) {
        return true;
      }
    }
    return false;
  };

  // api-dispatcher always concatenates the region to the title of the announcement, as defined in statusio_handler.py
  const getStatusIoRegion = (region: string, timezone: string) => {
    switch (region) {
      case "us-1": {
        // New Zealand features two different timezones
        const nzTimezones = ["Pacific/Auckland", "Pacific/Chatham"];
        if (nzTimezones.includes(timezone)) {
          return "New Zealand";
        }
        // Non New Zealand timezones are considered USA
        return "USA";
      }
      case "syd-2": {
        return "Australia";
      }
      case "uk-1": {
        return "United Kingdom";
      }
      // If the region is a development region, show all announcements
      default: {
        return "";
      }
    }
  };

  const statusChange = (statusio: StatusIoResponse) => {
    if (!statusio.announcements) {
      return;
    }

    const { region, timezone } = getDevice();

    const statusIoRegion = getStatusIoRegion(region, timezone);

    const filterFunc = (announcement: Announcement) => {
      // Filter out announcements that are already displayed
      if (checkIfAnnouncementExists(announcement.id)) {
        return false;
      }

      // If the region is a production region, only show announcements that are for the current region
      const productionRegions = ["us-1", "syd-2", "uk-1"];
      if (productionRegions.includes(region)) {
        return announcement.title.includes(statusIoRegion);
      }

      // If the region is a development region, show all announcements
      return true;
    };

    let announcementsNew = [...announcements];

    statusio.announcements
      // Filter out announcements that are already displayed or not in the current region
      .filter(filterFunc)
      .forEach((announcement) => {
        if (announcement.type !== "System Incident") {
          announcementsNew = announcementsNew.concat(formatTime(announcement));
        } else {
          announcementsNew = announcementsNew.concat(announcement);
        }
      });

    setAnnouncements(announcementsNew);
  };

  const formatTime = (announcement: Announcement) => {
    if (announcement.type === "SYSTEM OUTAGE") {
      return announcement;
    }
    announcement.title = announcement.title.concat(
      dayjs(announcement.start).format("DD/MM/YY HH:mm"),
      " until ",
      dayjs(announcement.end).format("DD/MM/YY HH:mm")
    );
    return announcement;
  };

  const closeAnnouncement = (announcementId: string) => {
    const announcementsNew = [...announcements];

    for (let i = 0; i < announcementsNew.length; i++) {
      if (announcementsNew[i].id === announcementId) {
        announcementsNew[i].hideme = true;
        break;
      }
    }
    setAnnouncements(announcementsNew);
  };

  const handleLink = () => {
    window.open("http://status.linewize.net/", "_blank");
  };

  return (
    <div className="announcement-bar-container">
      {announcements
        ? announcements.map((announcement) => {
            if (announcement.hideme) {
              return null;
            }
            return (
              <div className="status-bar-open" key={announcement.id}>
                <div className="announcement-type">{announcement.type}</div>
                <div className="announcement-spacer">-</div>
                <div className="announcement-title">{announcement.title}</div>
                <div className="announcement-name">{announcement.name}</div>
                <button type="button" className="announcement-link" onClick={handleLink}>
                  VIEW DETAILS
                </button>
                <button onClick={() => closeAnnouncement(announcement.id)} className="ab-close-button">
                  <img src={closeIcon} alt="Close announcement" />
                </button>
              </div>
            );
          })
        : null}
    </div>
  );
};
