import Api from "../utils/Api";

import alt from "../alt";
import SessionActions from "../actions/SessionActions";
import { useDevicePermissionsStore } from "../storez/DevicePermissionsStore";
import { useDateRangeFilterStore } from "../storez/DateRangeFilterStore";
import { initLogRocket } from "../utils/logRocketUtils";
import { useSessionStore } from "../storez/SessionStore";
import { initGoogleTagManager } from "../utils/googleTagManagerUtils";
import { useUserflowStore } from "../storez/UserflowStore";

const clearLocalStorage = () => {
  localStorage.removeItem("DEVICEID");
  localStorage.removeItem("DEVICE_NAME");
  localStorage.removeItem("USERNAME");
  localStorage.removeItem("USERID");
  localStorage.removeItem("TOKEN");
  localStorage.removeItem("SUPPORTADMIN");
  localStorage.removeItem("MOBILEZONEORGID");
  localStorage.removeItem("DEVICE_REGION");
  localStorage.removeItem("DEVICE_TIMEZONE");
  localStorage.removeItem("DEVICE_HUBSPOT_COMPANY_ID");
  localStorage.setItem("manuallySetRunningSyncs", "{}");
};

class SessionStore {
  constructor() {
    this.selected_device = localStorage.getItem("DEVICEID");
    this.selected_device_name = localStorage.getItem("DEVICE_NAME");
    this.selected_device_region = localStorage.getItem("DEVICE_REGION");
    this.username = localStorage.getItem("USERNAME");
    this.user_id = localStorage.getItem("USERID");
    this.token = localStorage.getItem("TOKEN");
    this.support_admin = localStorage.getItem("SUPPORTADMIN") === "true";
    this.mobilezone_orgid = localStorage.getItem("MOBILEZONEORGID");
    this.hubspot_company_id = localStorage.getItem("DEVICE_HUBSPOT_COMPANY_ID");
    this.available_devices = [];
    this.login_failed = false;
    this.login_failed_reason = "";
    this.account_device_permissions = [];
    this.account_device_permissions_loaded = false;
    this.available_devices_loaded = false;
    this.logRocketInitialised = false;
    this.googleTagManagerInitialised = false;

    if (this.support_admin) {
      useDevicePermissionsStore.setState({ supportAdmin: this.support_admin });
    }

    const sessionState = {
      user: { id: this.user_id, username: this.username },
      token: this.token,
      supportAdmin: this.support_admin,
    };

    if (this.selected_device) {
      sessionState.device = {
        id: this.selected_device,
        name: this.selected_device_name,
        region: this.selected_device_region,
        timezone: localStorage.getItem("DEVICE_TIMEZONE"),
      };
      this.initGoogleTagManagerIfRequired(this.selected_device_region);
    }

    useSessionStore.setState(sessionState);

    this.exportPublicMethods({
      getSelectedDevice: this.getSelectedDevice,
      getSelectedDeviceName: this.getSelectedDeviceName,
      getSelectedRegion: this.getSelectedRegion,
      getAvailableDevices: this.getAvailableDevices,
      getAvailableDevicesLoaded: this.getAvailableDevicesLoaded,
      getUsername: this.getUsername,
      hasDevice: this.hasDevice,
      isAuthenticated: this.isAuthenticated,
      getToken: this.getToken,
      getUserId: this.getUserId,
      failedLogin: this.failedLogin,
      resetLoginFailure: this.resetLoginFailure,
      failedLoginReason: this.failedLoginReason,
      getDevicePermissions: this.getDevicePermissions,
      isSupportAdmin: this.isSupportAdmin,
      isMobilezoneEnabled: this.isMobilezoneEnabled,
      hasDevicePermissionsLoaded: this.hasDevicePermissionsLoaded,
    });

    this.bindListeners({
      handleChangeDevice: SessionActions.CHANGE_DEVICE,
      handleFetchAvailableDevices: SessionActions.FETCH_AVAILABLE_DEVICES,
      handleFetchAvailableDevicesUpdated: SessionActions.FETCH_AVAILABLE_DEVICES_UPDATED,
      handleFetchAccountDevicePermissions: SessionActions.FETCH_ACCOUNT_DEVICE_PERMISSIONS,
      handleFetchAccountDevicePermissionsUpdated: SessionActions.FETCH_ACCOUNT_DEVICE_PERMISSIONS_UPDATED,

      handleAuthenticate: SessionActions.AUTHENTICATE,
      handleAuthenticateSuccess: SessionActions.AUTHENTICATE_SUCCESS,
      handleAuthenticateFailure: SessionActions.AUTHENTICATE_FAILURE,
      handleLogout: SessionActions.LOGOUT,

      handleDeselectDevice: SessionActions.DESELECT_DEVICE,
      handleInvalidSessionError: SessionActions.INVALID_SESSION_ERROR,
    });
  }

  /**
   * @deprecated Use {@link useSessionStore} instead.
   */
  getSelectedDevice = () => {
    return this.selected_device;
  };

  /**
   * @deprecated Use {@link useSessionStore} instead.
   */
  getSelectedRegion = () => {
    return this.selected_device_region;
  };

  /**
   * @deprecated Use {@link useSessionStore} instead.
   */
  getSelectedDeviceName = () => {
    return this.selected_device_name;
  };

  getAvailableDevices = () => {
    return this.available_devices;
  };

  /**
   * @deprecated Use {@link useSessionStore} instead.
   */
  getUsername = () => {
    return this.username;
  };

  getAvailableDevicesLoaded = () => {
    return this.available_devices_loaded;
  };

  /**
   * @deprecated Use {@link useSessionStore} instead.
   */
  hasDevice = () => {
    return !!this.selected_device;
  };

  /**
   * @deprecated Use {@link useSessionStore} instead.
   */
  isAuthenticated = () => {
    return !!this.token;
  };

  /**
   * @deprecated Use {@link useSessionStore} instead.
   */
  getToken = () => {
    return this.token;
  };

  getUserId = () => {
    return this.user_id;
  };

  /**
   * @deprecated Use {@link useDevicePermissionsStore} instead.
   */
  getDevicePermissions = () => {
    return this.account_device_permissions;
  };

  /**
   * @deprecated Use {@link useDevicePermissionsStore} instead.
   */
  hasDevicePermissionsLoaded = () => {
    return this.account_device_permissions_loaded;
  };

  /**
   * @deprecated Use {@link useSessionStore} or {@link useDevicePermissionsStore} instead.
   */
  isSupportAdmin = () => {
    return this.support_admin === true;
  };

  isMobilezoneEnabled = () => {
    if (this.hasDevice()) {
      return this.mobilezone_orgid !== undefined && this.mobilezone_orgid && this.mobilezone_orgid !== "None";
    }
    return false;
  };

  handleChangeDevice = (device) => {
    this.selected_device = device.deviceid;
    localStorage.setItem("DEVICEID", device.deviceid);
    this.selected_device_name = device.device.user_defined_name;
    localStorage.setItem("DEVICE_NAME", device.device.user_defined_name);
    this.selected_device_region = device.device.region;
    localStorage.setItem("DEVICE_REGION", device.device.region);
    localStorage.setItem("DEVICE_TIMEZONE", device.device.timezone);

    this.hubspot_company_id = device.device.hubspot_company_id;
    localStorage.setItem("DEVICE_HUBSPOT_COMPANY_ID", this.hubspot_company_id);
    this.account_device_permissions_loaded = false;

    this.mobilezone_orgid = device.device.mobilezone_orgid;
    localStorage.setItem("MOBILEZONEORGID", device.device.mobilezone_orgid);

    if (!this.support_admin) {
      this.initLogRocketIfRequired(this.selected_device, this.username);
    }

    this.initGoogleTagManagerIfRequired(this.selected_device_region);

    useSessionStore.setState({
      device: {
        id: this.selected_device,
        region: this.selected_device_region,
        name: this.selected_device_name,
        timezone: device.device.timezone,
      },
    });

    setTimeout(() => {
      SessionActions.fetchAccountDevicePermissions();
      useUserflowStore.getState().verifyAuth();
    }, 0);
  };

  handleFetchAccountDevicePermissions = () => {
    this.account_device_permissions = [];
    this.account_device_permissions_loaded = false;
    Api.get("/managedevice/ajax/device/permissions", (result) => {
      SessionActions.fetchAccountDevicePermissionsUpdated(result);
    });
  };

  handleFetchAccountDevicePermissionsUpdated = (result) => {
    for (let account of result["data"]) {
      if (account["accountid"] === Number(this.user_id)) {
        this.account_device_permissions = account["permissions"];
        if (account["users"]) {
          this.account_device_permission_users = account["users"];
        }
        if (account["groups"]) {
          this.account_device_permission_groups = account["groups"];
        }
        this.account_device_permissions_loaded = true;

        break;
      }
    }

    useDevicePermissionsStore.setState({
      loaded: this.account_device_permissions_loaded,
      permissions: this.account_device_permissions,
    });
  };

  handleFetchAvailableDevices = () => {
    if (this.available_devices.length === 0) {
      Api.get("/ajax/devices", function (result) {
        setTimeout(() => SessionActions.fetchAvailableDevicesUpdated(result), 0);
      });
    }
  };

  handleFetchAvailableDevicesUpdated = (result) => {
    this.available_devices_loaded = true;
    this.available_devices = result["devices"];
  };

  handleAuthenticate = (creds) => {
    this.username = undefined;
    this.token = undefined;
    this.support_admin = false;
    this.available_devices = [];
    this.available_devices_loaded = false;
    this.account_device_permissions_loaded = false;
    clearLocalStorage();

    Api.unauthenticated_post(
      "/authenticate",
      creds,
      function (data) {
        SessionActions.authenticateSuccess(data);
      },
      function (data) {
        SessionActions.authenticateFailure(data);
      }
    );
  };

  handleAuthenticateSuccess = (data) => {
    useDateRangeFilterStore.getState().reset();
    clearLocalStorage();
    localStorage.setItem("USERNAME", data.username);
    localStorage.setItem("USERID", data.id);
    localStorage.setItem("TOKEN", data.token);
    if (data.support_admin) {
      localStorage.setItem("SUPPORTADMIN", data.support_admin);
      this.support_admin = data.support_admin;
      useDevicePermissionsStore.setState({ supportAdmin: this.support_admin });
    }

    this.username = data.username;
    this.token = data.token;
    this.user_id = data.id;
    this.login_failed = false;
    this.login_failed_reason = "";

    useSessionStore.setState({
      user: { id: this.user_id, username: this.username },
      token: this.token,
      supportAdmin: !!this.support_admin,
    });
  };

  handleAuthenticateFailure = (data) => {
    clearLocalStorage();

    this.login_failed = true;
    this.login_failed_reason = data["error"];
  };

  resetLoginFailure = () => {
    this.login_failed = false;
    this.login_failed_reason = "";
  };

  handleInvalidSessionError() {
    this.handleLogout();
  }

  handleLogout() {
    if (this.token) Api.post("/authenticate/logout");

    clearLocalStorage();
    this.username = undefined;
    this.token = undefined;
    this.user_id = undefined;
    this.selected_device = undefined;
    this.selected_device_name = undefined;
    this.selected_device_region = undefined;
    this.hubspot_company_id = undefined;
    this.available_devices = [];
    this.available_devices_loaded = false;
    this.account_device_permissions = [];
    this.account_device_permission_users = [];
    this.account_device_permission_groups = [];
    this.account_device_permissions_loaded = false;
    this.login_failed = false;
    this.login_failed_reason = "";
    this.support_admin = false;
    this.mobilezone_orgid = undefined;
    this.logRocketInitialised = false;
    this.deviceId = undefined;

    useSessionStore.getState().reset();
    useDevicePermissionsStore.getState().reset();
    useUserflowStore.getState().resetUserflow();
  }

  handleDeselectDevice = () => {
    this.selected_device = undefined;
    localStorage.removeItem("DEVICEID");

    this.selected_device_name = undefined;
    localStorage.removeItem("DEVICE_NAME");

    this.selected_device_region = undefined;
    localStorage.removeItem("DEVICE_REGION");

    localStorage.removeItem("DEVICE_TIMEZONE");

    this.mobilezone_orgid = undefined;
    localStorage.removeItem("MOBILEZONEORGID");

    this.hubspot_company_id = undefined;
    localStorage.removeItem("DEVICE_HUBSPOT_COMPANY_ID");

    this.account_device_permissions = [];
    this.account_device_permissions_loaded = false;

    useSessionStore.setState({ device: undefined });
    useDevicePermissionsStore.setState({
      loaded: false,
      permissions: [],
    });
  };

  failedLogin = () => {
    return this.login_failed;
  };

  failedLoginReason = () => {
    return this.login_failed_reason;
  };

  initLogRocketIfRequired = (selected_device, username) => {
    if (process.env.NODE_ENV !== "production") {
      return;
    }

    if (this.logRocketInitialised) {
      return;
    }

    initLogRocket(selected_device, username);
    this.logRocketInitialised = true;
  };

  initGoogleTagManagerIfRequired = (applianceRegion) => {
    if (process.env.NODE_ENV !== "production") {
      return;
    }

    if (this.googleTagManagerInitialised) {
      return;
    }

    initGoogleTagManager(applianceRegion);
    this.googleTagManagerInitialised = true;
  };
}

export default alt.createStore(SessionStore, "SessionStore");
