import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  InlineNotification,
  Input,
  Option,
  Text,
  Tooltip,
  useToast,
  useTranslation,
} from "@familyzone/component-library";
import { ResponseError } from "../../../types/Api";
import { Guardian } from "../../../types/Community";
import { ManageUserGuardians } from "./ManageUserGuardians";
import CardBasedPage from "../../templates/CardBasedPage";
import { UserClassrooms } from "./ManageUserClassrooms";
import { getDisabledNotificationReason, getMissingRequiredFieldsForLinking, isUserEditable } from "./ManageUserHelpers";
import {
  addSchoolsForUserApiCall,
  deleteSchoolsForUserApiCall,
  getUserById,
  getUserByUsername,
  patchUsersApiCall,
} from "../../../utils/api/Users";
import { MultiGroupSearchSelector } from "../../GroupSearch/MultiGroupSearchSelector";
import SetPasswordModal from "./SetPasswordModal";
import { mapGroupsToOptionsNoLabelFallbackWithID } from "../../GroupSearch/GroupSearchHelper";
import { UserUMS } from "../../../types/Users";
import ComponentLoading from "../../../modules/ComponentLoading";
import { subtract } from "../DiffSetHelper";
import { RouterContext } from "../../../utils/RouterContext";
import PropTypes from "prop-types";
import { useFeatureFlagStore } from "../../../storez/FeatureFlagStore";
import ArchivedStatusPill from "../ArchivedStatusPill";
import { MultiSchoolSearchSelector } from "../../SchoolSearch/MultiSchoolSearchSelector";
import { School } from "../../../types/Schools";
import { mapSchoolsToOptions } from "../../SchoolSearch/SchoolSearchHelper";
import UserSchoolRoleModal from "./UserSchoolRoleModal";

interface Props {
  params: {
    id?: string;
    username?: string;
  };
}

const ManageUserById: FC<Props> = ({ params }, context: RouterContext) => {
  const { t } = useTranslation();

  const [user, setUser] = useState<UserUMS>();
  const [savedUser, setSavedUser] = useState<UserUMS>();
  const [guardians, setGuardians] = useState<Guardian[]>([]);
  const [saving, setSaving] = useState(false);
  const [saveButtonToolTipMessage, setSaveButtonToolTipMessage] = useState("");
  const [canSave, setCanSave] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState<Option[]>([]);
  const [selectedSchools, setSelectedSchools] = useState<Option[]>([]);
  const [changed, setChanged] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState<boolean>(false);
  const [isUserRoleModalOpen, setIsUserRoleModalOpen] = useState<boolean>(false);

  const { errorToast, successToast } = useToast();

  const [featureFlags, getOrFetchFeatureFlags] = useFeatureFlagStore(useCallback((state) => [state.flags, state.getOrFetch] as const, []));

  useEffect(() => {
    void Promise.all([getOrFetchFeatureFlags()]).catch((err: ResponseError) => {
      errorToast({
        title: t("Please try again"),
        description: t(err.message),
        isClosable: true,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOrFetchFeatureFlags]);

  const setUserFromUMS = (userID: string) => {
    const getUserPromise = getUserById(userID);
    void getUserPromise
      .then((user) => {
        setUser(user);
        setSelectedGroups(mapGroupsToOptionsNoLabelFallbackWithID(user.groups));
        setSelectedSchools(mapSchoolsToOptions(user.schools));
        setErrorState(false);
      })
      .catch(() => {
        setErrorState(true);
      });
  };

  useEffect(() => {
    let getUserPromise;
    if (params.id) {
      getUserPromise = getUserById(params.id);
    } else if (params.username) {
      getUserPromise = getUserByUsername(params.username);
    }
    if (getUserPromise) {
      void getUserPromise
        .then((user) => {
          setUser(user);
          setSavedUser(user);
          setSelectedGroups(mapGroupsToOptionsNoLabelFallbackWithID(user.groups));
          setSelectedSchools(mapSchoolsToOptions(user.schools));
          setErrorState(false);
        })
        .catch(() => {
          setErrorState(true);
        });
    }
  }, [params]);

  useEffect(() => {
    if (user !== undefined && featureFlags) {
      const isLocal = user.sourceType.toLowerCase() === "local";
      setShowPassword(isLocal && !!featureFlags["show-manage-user-password"]);
    }
  }, [user, featureFlags]);

  const handleChangeFirstName = (event: ChangeEvent<HTMLInputElement>) => {
    if (!user) return;
    setUser({ ...user, firstName: event.target.value });
    setChanged(true);
  };

  const handleChangeLastName = (event: ChangeEvent<HTMLInputElement>) => {
    if (!user) return;
    setUser({ ...user, lastName: event.target.value });
    setChanged(true);
  };

  const showSuccessToast = (title: string, message: string) => {
    successToast({
      title: t(title),
      description: t(message),
      duration: 3000,
      isClosable: true,
    });
  };

  const showErrorToast = (message: string) => {
    errorToast({
      title: t("Something went wrong"),
      description: t(message),
      duration: 3000,
      isClosable: true,
    });
  };

  useEffect(() => {
    if (!user) return;
    const missingFields = getMissingRequiredFieldsForLinking(user);
    if (guardians.length > 0 && missingFields.length > 0) {
      setSaveButtonToolTipMessage(`User must have ${missingFields.join(", ")} to be linked to a parent`);
      setCanSave(false);
    } else {
      setSaveButtonToolTipMessage("");
      setCanSave(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, guardians.length]);

  const handleSave = () => {
    if (!user) return;

    setSaving(true);

    const schoolsToAdd = Array.from(
      subtract(new Set(selectedSchools.map((s) => s.value.toString())), new Set(user.schools.map((s: School) => s.id)))
    );

    if (schoolsToAdd.length > 0) {
      setIsUserRoleModalOpen(true);
      return;
    }

    saveUser();
  };

  const saveUser = (role = "") => {
    if (!user) return;
    setSaving(true);

    let patchUser = null;
    if (user.sourceType.toLowerCase() === "local") {
      const groupsToAdd = Array.from(
        subtract(new Set(selectedGroups.map((g) => g.value.toString())), new Set(user.groups.map((g) => g.id.toString())))
      );

      const groupsToRemove = Array.from(
        subtract(new Set(user.groups.map((g) => g.id.toString())), new Set(selectedGroups.map((g) => g.value.toString())))
      );

      patchUser = {
        firstName: user.firstName,
        lastName: user.lastName,
        groupsToAdd,
        groupsToRemove,
      };
    }

    let schoolDeletions = null;
    const schoolsToRemove = Array.from(
      subtract(new Set(user.schools.map((s: School) => s.id)), new Set(selectedSchools.map((s) => s.value.toString())))
    );
    if (schoolsToRemove.length > 0) {
      schoolDeletions = {
        schoolIDs: schoolsToRemove,
      };
    }

    let schoolAdditions = null;
    const schoolsToAdd = Array.from(
      subtract(new Set(selectedSchools.map((s) => s.value.toString())), new Set(user.schools.map((s: School) => s.id)))
    );
    if (schoolsToAdd.length > 0) {
      schoolAdditions = {
        role: role,
        schoolIDs: schoolsToAdd,
      };
    }

    const promises = [];
    if (patchUser != null) {
      promises.push(patchUsersApiCall(user.id, patchUser));
    }
    if (schoolDeletions != null) {
      promises.push(deleteSchoolsForUserApiCall(user.stableId, schoolDeletions));
    }
    if (schoolAdditions != null) {
      promises.push(addSchoolsForUserApiCall(user.stableId, schoolAdditions));
    }

    Promise.all(promises)
      .then(() => {
        setUserFromUMS(user.id);
        setSavedUser(user);
        showSuccessToast("Success", "User has been updated successfully");
      })
      .catch(() => {
        showErrorToast("Sorry, there was an error saving user details, please try again");
      })
      .finally(() => {
        setSaving(false);
        setChanged(false);
        setIsUserRoleModalOpen(false);
      });
  };

  const breadcrumbs = [
    { title: t("Configuration"), url: "/config", isActive: false },
    { title: t("Users and Groups"), url: "/config/device/userdb", isActive: false },
    { title: t("Users"), url: "/config/device/userdb/users", isActive: false },
    { title: t("Manage User"), isActive: true },
  ];

  const onGroupChange = (groups: Option[]) => {
    setSelectedGroups(groups);
    setChanged(true);
  };

  const onGroupClick = (group: Option) => {
    context.router.push(`/config/device/userdb/groups/${group.value}`);
  };

  const onSchoolChange = (schools: Option[]) => {
    setSelectedSchools(schools);
    setChanged(true);
  };

  if (errorState)
    return (
      <Box m="sp24">
        <InlineNotification
          status="error"
          notificationTitle={t("Error loading user")}
          notificationDescription={t("There's a problem loading this user right now. Please try again later.")}
        />
      </Box>
    );

  if (!savedUser || !user) {
    return (
      <div className="centered">
        <ComponentLoading />
      </div>
    );
  }

  return (
    <CardBasedPage title={"Manage User"} breadcrumbs={breadcrumbs}>
      <Box p="sp8">
        <Box mb="sp24">
          {!isUserEditable(user) && (
            <Box pt="sp12">
              <InlineNotification
                status="warning"
                notificationTitle={t("Editing disabled")}
                notificationDescription={t(getDisabledNotificationReason(user))}
              />
            </Box>
          )}
          <Flex gap="sp8" py="sp8">
            <Text fontFamily="heading" fontSize="xl" color="text.title" role="heading">
              User Details
            </Text>
            {user.archived && <ArchivedStatusPill archived={user.archived} />}
          </Flex>
          <Flex>
            <Flex flexDir="column" py="sp12" justifyContent="space-between" mr="14px">
              <Text>Username</Text>
              <Text>First Name</Text>
              <Text>Last Name</Text>
              <Text>Email</Text>
              <Text>Provider</Text>
              <Text>Provider DN</Text>
              {showPassword && <Text>Password</Text>}
            </Flex>
            <Flex flexDir="column" justifyContent="space-evenly" width="60%">
              <Input value={user.username} margin="0px" mb="sp4" data-testid="username-input" isDisabled />
              <Input
                value={user.firstName}
                margin="0px"
                mb="sp2"
                data-testid="firstName-input"
                onChange={handleChangeFirstName}
                isDisabled={!isUserEditable(user)}
                aria-label={t("First Name")}
              />
              <Input
                value={user.lastName}
                margin="0px"
                mb="sp2"
                data-testid="lastName-input"
                onChange={handleChangeLastName}
                isDisabled={!isUserEditable(user)}
                aria-label={t("Last Name")}
              />
              <Input value={user.email} margin="0px" mb="sp2" data-testid="email-input" isDisabled />
              <Input value={user.sourceType} margin="0px" mb="sp2" data-testid="provider-input" isDisabled />
              <Input value={user.distinguishedName} margin="0px" mb="sp2" data-testid="dn-input" isDisabled />
              {showPassword && (
                <>
                  <Button
                    variant="secondary"
                    w="200px"
                    mb="sp4"
                    onClick={() => {
                      setIsPasswordModalOpen(true);
                    }}
                  >
                    Set Password
                  </Button>
                  <SetPasswordModal
                    open={isPasswordModalOpen}
                    onClose={() => {
                      setIsPasswordModalOpen(false);
                    }}
                    username={user?.username ?? ""}
                  />
                </>
              )}
            </Flex>
          </Flex>
        </Box>

        <ManageUserGuardians student={{ ...savedUser, id: savedUser.stableId }} onGuardiansChange={setGuardians} />

        <Box my="sp24">
          <Text fontFamily="heading" fontSize="xl" color="text.title" mb="sp4" role="heading">
            Group Membership
          </Text>
          <Box data-testid="manage-user-group-selector">
            <MultiGroupSearchSelector
              preselected={selectedGroups}
              onChangeGroups={onGroupChange}
              onClickGroup={onGroupClick}
              disabled={!isUserEditable(user)}
              useLegacyId={true}
            />
          </Box>
        </Box>

        <Box mb="sp8">
          <Text fontFamily="heading" fontSize="xl" color="text.title" mb="sp4" role="heading">
            Classrooms
          </Text>
          <UserClassrooms loaded={true} classrooms={user.classrooms} />
        </Box>

        <Box mb="sp8">
          <Text fontFamily="heading" fontSize="xl" color="text.title" mb="sp4" role="heading">
            Schools
          </Text>
          <Box data-testid="manage-user-school-selector">
            <MultiSchoolSearchSelector preselected={selectedSchools} onChangeSchools={onSchoolChange} disabled={user && user.archived} />
          </Box>
        </Box>

        <Flex>
          <Tooltip
            label={t(saveButtonToolTipMessage)}
            placement="top-start"
            hasArrow={true}
            maxWidth="304px"
            variant="dark"
            isDisabled={!changed && !saving && canSave}
          >
            <Box>
              {user && !user.archived && (
                <Button onClick={handleSave} disabled={!changed || !canSave} variant="primary">
                  {t("Save")}
                </Button>
              )}
            </Box>
          </Tooltip>
        </Flex>
      </Box>
      <UserSchoolRoleModal
        open={isUserRoleModalOpen}
        onClose={() => {
          setIsUserRoleModalOpen(false);
        }}
        userFirstName={user.firstName}
        userLastName={user.lastName}
        saveUser={saveUser}
      />
    </CardBasedPage>
  );
};

export default ManageUserById;
ManageUserById.contextTypes = {
  router: PropTypes.object.isRequired,
};
